.checkout {
  margin-top: 34px;
  &.content {
    .title {
      height: 49px;
    }
  }
  .privacy-text {
    color: $text-light-color;
  }
  .messages {
    padding: 0 16px;
    .animate-message.wiggle {
      animation: wiggle 1.25s ease-in-out 2 alternate;
    }
    .message-warning {
      .message-warning-item {
        margin-left: 5px;
        text-align: left;
      }
    }
  }
  .checkout-loading {
    margin-top: 0;
    .messages {
      padding: 0;
    }
    .action-container {
      margin: 10px;
    }
    .status-text {
      margin: 16px auto;
    }
  }
  .section {
    .card-title {
      font-size: 18px;
    }
    .details {
      margin-top: 8px;
    }
    .sub-text {
      color: $color-gray;
    }
    .icon-gradient {
      background: linear-gradient(to left, #0075be, #74dafc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .icon-default {
      color: $color-gray;
    }
    .icon-form {
      @include size_square(24px);
      font-size: 20px;
    }
    v-pane-content {
      & > div {
        margin-top: 16px;
        padding-bottom: 0;
        & > md-list {
          padding: 0;
        }
      }
    }
    &.checkout-invoice {
      margin-bottom: 0;
    }
    &.checkout-payment {
      .payment-container {
        .payment-options {
          width: 100%;
        }
        .payment-iframe {
          width: 100%;
          height: 170px;
          overflow: hidden;
        }
      }
      v-pane-header {
        .panel-title {
          font-size: 13px;
        }
      }
      md-radio-group {
        text-align: right;
        md-radio-button {
          display: inline;
          .md-container.md-ink-ripple {
            display: none;
          }
          .md-label {
            margin: 0;
          }
          .visa {
            background-image: url('/img/checkout/visa.svg');
            &.inactive {
              background-image: url('/img/checkout/visa-inactive.svg');
            }
          }
          .master {
            background-image: url('/img/checkout/mastercard.svg');
            &.inactive {
              background-image: url('/img/checkout/mastercard-inactive.svg');
            }
          }
          .amex {
            background-image: url('/img/checkout/amex.svg');
            &.inactive {
              background-image: url('/img/checkout/amex-inactive.svg');
            }
          }
          .visa,
          .master,
          .amex {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 36px;
            height: 34px;
          }
        }
      }
      .saved-cards {
        margin-top: 0;
        padding-left: 0;
        max-width: 336px;
        .card {
          border-radius: 8px;
          color: $text-white-color;
          padding: 4px 16px;
          margin-top: 8px;
          position: relative;
          background-repeat: no-repeat;
          .panel-title {
            color: $text-white-color;
          }
          .company-card-logo {
            width: 36px;
            height: 24px;
            margin: 8px 0;
          }
          #pane-profile-payments-card-content {
            div {
              margin-top: 0;
              .card-number {
                font-size: 16px;
                letter-spacing: 2px;
                margin-top: 12px;
              }
              .card-body {
                margin: 28px 0 8px 0;
              }
            }
          }
          &.visa-card {
            background-image: url('/img/checkout/payment-cards/visa.svg');
          }
          &.master-card {
            background-image: url('/img/checkout/payment-cards/mastercard.svg');
          }
          &.amex-card {
            background-image: url('/img/checkout/payment-cards/amex.svg');
          }
          &.is-expanded {
            margin: 8px 0 8px 0;
          }
          &:first-child {
            margin-top: 0 !important;
          }
          &:last-child{
            margin: 8px 0 16px 0;
          }
        }
      }
    }
    &.checkout-basket {
      .basket-text {
        color: $color-gray;
        margin: 16px 0 0 16px;
      }
      .terms {
        color: $text-light-color;
        padding: 0 16px;
        float: left;
      }
      .row {
        padding: 16px 8px;
      }
      #basket-accordion {
        margin: 0 8px;
      }
      v-pane-content {
        & > div {
          margin-top: 0;
        }
        .element-title {
          margin-bottom: 4px;
        }
        .elements-container {
          margin-bottom: 16px;
        }
        .item-row {
          span {
            &:not(:first-child) {
              text-align: right;
            }
          }
        }
        md-list-item {
          padding: 0 16px;
        }
      }
      .basket-bottom {
        .total-price {
          padding: 16px;
          border-top: 1px solid $icon-lighter-gray-color;
        }
        .total-price {
          font-size: 16px;
        }
        .terms {
          padding-bottom: 12px;
        }
      }
    }
    v-pane-header {
      .panel-title {
        font-size: 18px;
      }
    }
    .icon-header {
      background: linear-gradient(to left, #0075be, #74dafc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0 5px;
      max-height: 22px;
      &.icon-address {
        margin-left: -4px;
      }
      &.icon-payment {
        margin-left: -2px;
      }
    }
  }
  .submit-container {
    padding: 0 16px;
    margin-top: 25px;
    .input-terms {
      margin-bottom: 16px;
      & > div {
        margin-top: 8px;
      }
    }
    .button-action {
      margin-left: 0;
    }
  }

  .submit-container,
  .checkout-invoice,
  .checkout-payment {
    md-checkbox {
      margin-bottom: 0;
    }
  }

  v-accordion {
    .basket-hint {
      color: $color-gray;
    }
    v-pane-header {
      border-bottom: none !important;
      color: $text-dark-color;
      padding: 0;
      margin-bottom: 0 !important;
      &:hover,
      &:active,
      &:focus {
        color: $text-dark-color;
      }
      &:before, &:after {
        display: none;
      }
    }
  }

  @keyframes wiggle {
    0% {transform: none;}
    25% {transform: scale(1);}
    50% {transform: scale(2);}
    75% {transform: scale(1);}
    100% {transform: none;}
  }
}
